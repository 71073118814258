import React from 'react'
import '../assets/scss/main.scss'
import posed, { PoseGroup } from "react-pose"

import Header from './Header'

const Fade = posed.div({
  enter: {
    opacity: 1,
    // filter: "blur(0px)",
    y: 0,
    // delay: 0,
    // delayChildren: 0
  },
  exit: {
    opacity: 0,
    // filter: "blur(20px)",
  },
})

const Template = ({ children }) => {

  return(
    <div>
      <Header />
      <Fade>{children}</Fade>
    </div>
  )
}

export default Template
