import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="copyright">
        <li><a href="https://note.com/ryoonodera">&copy; 2020 Onodera Ryo</a></li>
      </ul>
    </div>
  </div>
)

export default Footer
