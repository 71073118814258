import React from 'react'

import Footer from './Footer'
import { Link } from 'gatsby'

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }


  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
    <header id="header">
      <div className="inner">
        <h1><Link to="/" className="main_title">RYO ONODERA</Link></h1>
        <div className={`button_menu ${this.state.navBarActiveClass}`} data-target="navMenu" onClick={() => this.toggleHamburger()}>
          <div class={`line line1 ${this.state.navBarActiveClass}`}></div>
          <div class={`line line2 ${this.state.navBarActiveClass}`}></div>
        </div>
        <nav class={`manu_nav ${this.state.navBarActiveClass}`}>
          <ul>
            <li class={`pc ${this.state.navBarActiveClass}`}>
              <label for="label1">Works</label>
              <input type="checkbox" id="label1" class="cssacc" />
              <div class="show">
                <Link to='/portrait' onClick={() => this.toggleHamburger()}>-Portrait</Link><br></br>
                <Link to="/fashion" onClick={() => this.toggleHamburger()}>-Fashion</Link><br></br>
                <Link to="/advertis" onClick={() => this.toggleHamburger()}>-Advertisement</Link><br></br>
              </div>
            </li>
            <li class={`sp ${this.state.navBarActiveClass}`}>
              <label for="label5">Works</label>
              <input type="checkbox" id="label5" class="cssacc" />
              <div class="show">
                <Link to='/portrait2020' onClick={() => this.toggleHamburger()}>-Portrait</Link><br></br>
                <Link to="/fashion-all" onClick={() => this.toggleHamburger()}>-Fashion</Link><br></br>
                <Link to="/advertis-all" onClick={() => this.toggleHamburger()}>-Advertisement</Link><br></br>
              </div>
            </li>
            <li class={`${this.state.navBarActiveClass}`}>
              <label for="label2">Exhibition</label>
              <input type="checkbox" id="label2" class="cssacc" />
              <div class="show">
                <Link to="/the-work-of-actors-and-painters" onClick={() => this.toggleHamburger()}>-The work of actors and painterss</Link><br></br>
                <Link to="/zotoka" onClick={() => this.toggleHamburger()}>-ZÔTÔKA</Link><br></br>
                <Link to="/01cm" onClick={() => this.toggleHamburger()}>-O.1CM</Link><br></br>
                <Link to="/nobody" onClick={() => this.toggleHamburger()}>-Nobody Knows</Link><br></br>
                <Link to="/yosomono" onClick={() => this.toggleHamburger()}>-YOSOMONO</Link><br></br>
              </div>
            </li>
            <li class={`${this.state.navBarActiveClass}`}>
            <label for="label3">Future in the Past</label>
              <input type="checkbox" id="label3" class="cssacc" />
              <div class="show">
                <Link to="/future-in-the-past-2019-2020" onClick={() => this.toggleHamburger()}>-2019 ~ 2020</Link><br></br>
                <Link to="/future-in-the-past-2016-2018" onClick={() => this.toggleHamburger()}>-2016 ~ 2018</Link><br></br>
                <Link to="/future-in-the-past-2015-2016" onClick={() => this.toggleHamburger()}>-2015 ~ 2016</Link><br></br>
              </div>
            </li>
            <li class={`${this.state.navBarActiveClass}`}>
            <label for="label4">Movie</label>
              <input type="checkbox" id="label4" class="cssacc" />
              <div class="show">
                <Link to="/commercial" onClick={() => this.toggleHamburger()}>-Commercial</Link><br></br>
                <Link to="/mv" onClick={() => this.toggleHamburger()}>-Music Video</Link><br></br>
                <Link to="/short-film" onClick={() => this.toggleHamburger()}>-Short Film</Link><br></br>
              </div>
            </li>
            <li class={`${this.state.navBarActiveClass}`}>
              <Link to="/cv" onClick={() => this.toggleHamburger()}>CV</Link>
            </li>
            <li class={`${this.state.navBarActiveClass}`}>
              <Link to="/contact" onClick={() => this.toggleHamburger()}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div class={`bg_nav ${this.state.navBarActiveClass}`}></div>
      </div>
      <Footer />
    </header>
    )
  }
}

export default Header